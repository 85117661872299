




































import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    participant: {
      type: Object,
      required: true,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const model = reactive({
      email: props.participant.invoiceEmail
        ? props.participant.invoiceEmail
        : props.participant.email,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      errorDialog: false,
      disableButton: false,
    });

    const closeDialog = () => {
      emit("close-sendDialog");
    };

    const closeSuccess = () => {
      state.errorDialog = false;
      emit("close-sendDialog");
      emit("fetch-data");
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        invoiceId: props.participant.invoices[0].invoiceId,
        email: model.email || undefined,
        participantId: props.participant.id,
      };

      state.loading = true;

      axiosInstance
        .post("invoice/mail", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          state.errorDialog = true;
          state.disableButton = true;
        })
        .catch((error) => {
          state.errorDialog = true;
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.participantNotFound")}`;
        case 406:
          return `${root.$tc("layout.errors.noFeeAssigned")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      model,
      state,
      closeDialog,
      onSubmit,
      closeSuccess,
      getErrorMessage,
    };
  },
});
